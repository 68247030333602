import React from 'react';
import { withRouter} from 'react-router-dom';
// import CookieConsent from "react-cookie-consent";

import LandingLayout from 'component/page/landing';
import Hero from 'component/content/hero';
import Products from 'component/content/products';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="landing-page my-5">
        {/* <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent> */}

        <div className="py-5">
          <Hero />
          <Products />
        </div>

      </LandingLayout>
    )
  }
}

export default withRouter(Page);
