import React from 'react';
import {Link} from 'react-router-dom';

import SocialComponent from './../reuse/social';
import { FooterSectionTitle } from './../reuse/title';
import { PROFILE } from "./../constant";

import {FooterLink, FooterExtLink} from './base';
import {CopyrightFooter, CompanyComponent, OtherProductsComponent, ReachComponent} from './common';


// function FooterLink(props){
//   return (
//     <div className={"uppercase m-2 text-spacing-2"}>
//       <Link className="black-bb-1-1" to={props.to}><i className="text-black-3"></i>{props.text}</Link>
//     </div>  
//   )
// }

// function FooterExtLink(props){
//   return (
//     <div className={"uppercase m-2 text-spacing-2"}>
//       <a className="black-bb-1-1" href={props.href}><i className="text-black-3"></i>{props.text}</a>
//     </div>  
//   )
// }

export function LoggedInFooter(props){
  return(
    <footer className={"gradient-dark"}>
      <div className="col"></div>
      <div className="col">
        <div className="text-white fa-pull-left  family-code ">
          <div className="pt-4 pb-2 uppercase text-spacing-2">
            <Link to="/">Home</Link>
            <span className="mx-2 text-white">|</span>
            <Link to="/legal/privacy">Privacy</Link>
            <span className="mx-2 text-white">|</span>
            <Link to="/legal/tos">Terms</Link>
          </div>
          <div className="pt-2 pb-4">
            © { new Date().getFullYear() } - <Link className=" uppercase text-spacing-2" to="/">ByteBeacon, Inc</Link> - All rights reserved
          </div>
        </div>
      </div>
      <div className="col"></div>
      <div className="col">
        <div className="fa-pull-right">
          <div className=" py-5 ">
          <SocialComponent contact={PROFILE.contact} />
          </div>
        </div>
      </div>
      <div className="col"></div>
    </footer>
  )
}


export function LandingFooter(props){
  const is_mobile = window.innerWidth < 1200;
  return(
    <footer>
      <div  className="p-1 pb-5 bg-footer box-shadow text-black-9">
        <div className="row m-0">
          <div className="col-sm-5 px-0"><AboutComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-0"><CompanyComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-0"><OtherProductsComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-3 px-0"><ReachComponent is_mobile={is_mobile} /></div>
        </div>
      </div>
      <CopyrightFooter padding={5} />
    </footer>
  )
}

function AboutComponent(props){
  return (
    <div className="">
      <div className="m-1">
        <h2 className="text-dark my-5 text-spacing-2 family-title">
          <img style={{height: "70px"}} className="hero mx-3" src="/logo.svg" alt="Byte beacon logo" />
          <span className="text-secondary">Byte</span><span className="text-primary">Beacon</span>
        </h2>
        <p className=" text-spacing-1 text-muted">Our mission is to empower businesses with self governing tools allowing them to grow their online sales faster.</p>
      </div>

    </div>
  )
}
