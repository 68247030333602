import React from "react";

// import { SectionTitle, SubSectionTitle } from 'component/reuse/block';

export default class Component extends React.Component {
  render() {
    return (
      <div id="products" className="my-5">
        {/* <div className="my-5">
          <SectionTitle first="What " second="we've been upto" background="secondary" flip={true} />
        </div> */}
        {/* <hr className="black-bb-1-1"/> */}

        <div className="mb-4">
          <div className="highlight-secondary-1 py-5"></div>
          <h1 className="family-h1 text-center jumbo-title my-5">
            {/* <span className="text-black-2">Some of </span>  */}
            <span className="text-primarya">Our </span>
            <span className="text-black-2"> </span>
            <span className="text-secondary">products</span>
          </h1>
        </div>

        <div className=" justify-content-between">
          <LightFlare flip={true} />
          <hr className=" m-5 black-bb-1-1" />
          <ByteTrack flip={false} />
          <hr className=" m-5 black-bb-1-1" />
          <DivShow flip={true} />
          {/* <hr className=" m-5 black-bb-1-1"/> */}
          {/* <SoftComm flip={true} /> */}
          <hr className=" m-5 black-bb-1-1" />
          <Navigator flip={false} />
          {/* <hr className=" m-5 black-bb-1-1"/> */}
          {/* <BrandCode flip={true} /> */}
          {/* <hr className=" m-5 black-bb-1-1"/> */}
          {/* <BeOnline flip={false} /> */}
        </div>
      </div>
    );
  }
}

function Slant(props) {
  const class_base = props.class_base || "primary";
  const slant_orientation = props.flip || false ? "right" : "left";
  const slant_orientation_opp = slant_orientation === "left" ? "right" : "left";

  return (
    <div className="">
      <div
        className={
          "slant slant-top-" + slant_orientation + "-" + class_base + "-1"
        }
      ></div>
      <div className={" highlight-" + class_base + "-1 "}>{props.children}</div>
      <div
        className={
          "slant slant-bottom-" +
          slant_orientation_opp +
          "-" +
          class_base +
          "-1"
        }
      ></div>
    </div>
  );
}

function ProductSection(props) {
  const item = props.item;

  return (
    <div className="">
      <div className="">
        <Slant class_base={item.class_base} flip={item.flip}>
          <div className="text-center">
            <a href={item.url} className="">
              <img
                className="img img-fluid btn-rounded mb-3"
                style={{ height: "50px" }}
                src={"/static/images/" + item.path_key + "/sign.png"}
                alt={item.path_key + " sign"}
              />
            </a>
            <h4 className=" text-spacing-2 mx-0 mb-4">{item.title}</h4>
          </div>

          <div className="container mb-3 mt-3">
            <div className="row">
              <div className="col-2 flex-center">
                <div className="img img-fluid text-center">
                  <a href={item.url} className="">
                    <img
                      style={{ height: "100px" }}
                      className="hero mx-3"
                      src={"/static/images/" + item.path_key + "/logo.svg"}
                      alt={item.path_key + " logo"}
                    />
                  </a>
                </div>
              </div>
              <div className="col-10">{props.children}</div>
            </div>
          </div>

          <div className="text-center py-3">
            <a href={item.url} className="">
              <img
                className="img img-fluid border rounded "
                src={"/static/images/" + item.path_key + "/demo.gif"}
                alt={item.path_key + " demo"}
              />
            </a>
          </div>
        </Slant>
      </div>
    </div>
  );
}

function LightFlare(props) {
  const item = {
    url: "https://lightflare.bytebeacon.com",
    path_key: "lightflare",
    class_base: "quinary",
    title: "Notifications as a service!",
    flip: props.flip || false,
  };

  return (
    <ProductSection item={item}>
      <div className="">
        <p className="">
          <a href={item.url} className="">
            LightFlare
          </a>{" "}
          allows you to send a notifications via simple POST requests.
        </p>
        <p className="">
          Get notified through email, sms, chat and app notifications with a
          POST request!
        </p>
      </div>
    </ProductSection>
  );
}

function ByteTrack(props) {
  const item = {
    url: "https://bytetrack.bytebeacon.com",
    path_key: "bytetrack",
    class_base: "tertiary",
    title: "Track recurring activities",
    flip: props.flip || false,
  };

  return (
    <ProductSection item={item}>
      <div className="">
        <p className="">
          <a href={item.url} className="">
            ByteTrack
          </a>{" "}
          is an easy way for you to track activities and get a report that
          motivates you further.
        </p>
        <p className="">
          In a fast moving world, its easy to forget how much you've acomplished
          and how far you've come! Keeping a log help you see it all.
        </p>
      </div>
    </ProductSection>
  );
}

function SoftComm(props) {
  const item = {
    url: "https://softcomm.bytebeacon.com",
    path_key: "softcomm",
    class_base: "secondary",
    title: "Monitor the pulse of your critical tasks!",
    flip: props.flip || false,
  };

  return (
    <ProductSection item={item}>
      <div className="">
        <p className="">
          <a href={item.url} className="">
            SoftComm
          </a>{" "}
          is a new kind of navigation tool that gives your users superpowers
          when browsing your website.
        </p>
        <p>Just talk to our bot through your preferred chat client!</p>
        <p>
          Information submitted is available for each member and rolled up at
          higher levels.
        </p>
        <p>Connect your existing platforms and improve your workflows</p>
        <p>API docs available for developers to build their own applications</p>
      </div>
    </ProductSection>
  );
}

function Navigator(props) {
  const item = {
    url: "https://navigator.bytebeacon.com",
    path_key: "navigator",
    class_base: "dark",
    title: "A smart (no mouse) navigation for your website: No Coding!",
    flip: props.flip || false,
  };

  return (
    <ProductSection item={item}>
      <div className="">
        <p className="">
          <a href={item.url} className="">
            Navigator
          </a>{" "}
          is a new kind of navigation tool that gives your users superpowers
          when browsing your website.
        </p>
        <p className="">
          Just hit <kbd className="bg-primary ">ctrl</kbd> +{" "}
          <kbd className="bg-primary ">space</kbd> to launch a spotlight /
          alfred like search bar and let your users search your site just like
          they'd search the web.
        </p>
        <p className="">
          No coding required. Just register and launch it on your website.
        </p>
        <p className="">
          Try it now by pressing <kbd className="bg-primary ">ctrl</kbd> +{" "}
          <kbd className="bg-primary ">space</kbd>
        </p>
      </div>
    </ProductSection>
  );
}

function DivShow(props) {
  const item = {
    url: "https://divshow.bytebeacon.com",
    path_key: "divshow",
    class_base: "primary",
    title: "Build amazing product walkthroughs in minutes",
    flip: props.flip || false,
  };

  return (
    <ProductSection item={item}>
      <div className="">
        <p className="">
          <a href={item.url} className="">
            DivShow
          </a>{" "}
          is a new kind of presentation tool that helps you get your product
          offering across using a presentation that runs on tops of your website
          canvas.
        </p>
        <p className="">
          It uses the website itself along with a recorded audio to guide users
          through the product features.
        </p>
        <p className="">There's no video, but there is a show!</p>
      </div>
    </ProductSection>
  );
}

function BrandCode(props) {
  const item = {
    url: "https://bc.bytebeacon.com",
    path_key: "brandcode",
    class_base: "quinary",
    title: "Generate BrandCode for your Brand!",
    flip: props.flip || false,
  };

  return (
    <ProductSection item={item}>
      <div className="">
        <p className="">
          <a href={item.url} className="">
            BrandCode
          </a>{" "}
          is your one stop shop for all your QR code marketing and tracking
          needs.
        </p>
        <p className="">
          We help you with brand code marketing dynamic QR Codes, responsive
          designs, analytics and critical insights for increased customer
          engagement.
        </p>
      </div>
    </ProductSection>
  );
}

function BeOnline(props) {
  const item = {
    url: "https://beonline.bytebeacon.com",
    path_key: "beonline",
    class_base: "quaternary",
    title: "Treat your influencers well!",
    flip: props.flip || false,
  };

  return (
    <ProductSection item={item}>
      <div className="">
        <p className="">
          <a href={item.url} className="">
            BeOnline
          </a>{" "}
          helps businesses identify their high influence customers and reward
          them with offers that they can promote and grow more business.
        </p>
        {/* <p className="">
        </p> */}
      </div>
    </ProductSection>
  );
}
