import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Router, Switch } from 'react-router-dom';

import {createBrowserHistory} from 'history';
import ReactGA from 'react-ga';

import LandingPage from './page/public/landing';
import ProductsPage from './page/public/products';
import NoMatchPage from './page/generic/no_match';

import LegalTOSPage from './page/legal/tos';
import PrivacyPolicyPage from './page/legal/privacy';

import { getUserId } from './utils/authentication';

import * as serviceWorker from './serviceWorker';

import './styles/bootstrap.min.css';
import './styles/animate.min.css';
import './styles/alertify.min.css';


const history = createBrowserHistory();

const trackingId = process.env.REACT_APP_GA_CODE;  
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname);


history.listen(location => {

  const on_sites = (location.pathname.match(/sites/gi) || []).length;
  const on_instructions = (location.pathname.match(/instructions/gi) || []).length;
  if (on_sites > 0 || on_instructions > 0){

  } else {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  
  if (location.pathname !== '/'){
    if (window.drift){
      window.drift.unload();
    }
  }

  // Update the user's current page
  ReactGA.set({ userId: getUserId(), page: location.pathname });
  // Record a pageview for the given page
  ReactGA.pageview(location.pathname); 
});


ReactDOM.render(
  <Router history={history}>
    <Switch>
      {/* Open pages */}
      <Route path='/legal/tos' component={LegalTOSPage} />
      <Route path='/legal/privacy' component={PrivacyPolicyPage} />
      <Route path='/products' component={ProductsPage} />
      <Route path='/' component={LandingPage} />

      {/* Logged in pages */}
      {/* <Route exact path='/' render={() => (hasStoredToken()? (<Redirect to={"/"}/>) : (<LandingPage />))}/> */}

      <Route component={NoMatchPage} />

    </Switch>
  </Router>
  , document.getElementById('page')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
