import React from "react";
import { withRouter, Link, NavLink } from "react-router-dom";

import { hasStoredToken, deleteToken } from "utils/authentication";
import Announcement from "component/content/announcement";

class Nav extends React.Component {
  render() {
    return (
      <section className={" fixed-top"}>
        {/* <Announcement /> */}

        <nav className={"navbar navbar-expand-lg p-0 bg-white box-shadow"}>
          <div className="navbar-header">
            <Link
              to="/"
              className="navbar-brand text-spacing-2 mr-2 family-title"
            >
              <img
                style={{ height: "40px" }}
                className="hero mx-3"
                src="/logo.svg"
                alt="ByteBeacon logo"
              />
              <span className="text-primary">Byte</span>
              <span className="text-secondary">Beacon</span>
            </Link>
          </div>

          <button
            className="navbar-toggler btn btn-primary text-white mr-2"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active"></li>
            </ul>

            {hasStoredToken() ? (
              <ul className="navbar-nav my-0 my-lg-0">
                {/* <li className="nav-item">
                <NavLink activeClassName="highlight-primary-8" className="highlight-primary-1 btn btn-rounded mx-1 uppercase" to="/instructions"><span className="fa fa-eye mr-2"></span>Instructions</NavLink>
              </li> */}
              </ul>
            ) : (
              <ul className="navbar-nav my-0 my-lg-0">
                {/* <li className="nav-item">
                <NavLink activeClassName="highlight-primary-6" className="highlight-primary-1 btn btn-rounded m-2 text-spacing-2 uppercase" to="/" >Home</NavLink>
              </li>*/}
                <li className="nav-item">
                  <NavLink
                    activeClassName="highlight-primary-6"
                    className="highlight-primary-1 btn btn-rounded m-2 text-spacing-2 uppercase"
                    to="/products"
                  >
                    Products
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a
                    className="highlight-primary-1 btn btn-rounded m-2 text-spacing-2 uppercase"
                    href="https://blog.bytebeacon.com"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </section>
    );
  }
}

export default withRouter(Nav);
