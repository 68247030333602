import React from 'react';
import { withRouter} from 'react-router-dom';

import LandingLayout from 'component/page/landing';
import Products from 'component/content/products';
// import { capture, OutputType } from 'html-screen-capture-js';


class Page extends React.Component {

  // onClick(e){
  //   const obj = capture();
  //   // const str = capture(OutputType.STRING);
  //   const str = capture(OutputType.STRING, document, {'imageFormatForDataUrl': 'image/png', 'imageQualityForDataUrl': 1.0});

  //   console.log(str);

  // }

  render (){
    return (
      <LandingLayout id="products-page">
        {/* <button className="btn btn-info" onClick={this.onClick.bind(this)}>Capture</button> */}
        <Products />
      </LandingLayout>
    )
  }
}

export default withRouter(Page);
