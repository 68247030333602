import React from 'react';
import { withRouter} from 'react-router-dom';

import LandingLayout from 'component/page/landing';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="404-page">

        <div className="container">
          <div className="spacer-20">

            <p className="lead">Content not found</p>

          </div>
        </div>

      </LandingLayout>
    )
  }
}

export default withRouter(Page);
