import API from 'utils/xhr';

// rename DIVSHOW_ to your custom project

function _getFromStorage(key){
  return localStorage.getItem("DIVSHOW_" + key);
}

function _setToStorage(key, data){
  localStorage.setItem("DIVSHOW_" + key, data);
}

function _removeFromStorage(key){
  localStorage.removeItem("DIVSHOW_" + key);
}


export function hasStoredToken(){
  return !!_getFromStorage("token");
}

export function getStoredToken(){
  return _getFromStorage("token");
}

export function saveToken(token){
  _setToStorage("token", token);
  API.defaults.headers['Authorization'] = 'Bearer ' + token;
}

export function deleteToken(){
  _removeFromStorage('token');
  clearLoginMeta();
}

export function saveLoginMeta(meta){
  _setToStorage("user_xid", meta.user_xid);
  _setToStorage("payment_tier_name", meta.payment_tier.tier.name);
  
  if (meta.payment_tier.tier_data === null){
    return;
  }
  console.log(meta.payment_tier.tier_data.offer_details);
  _setToStorage("offer_valid_until", meta.payment_tier.tier_data.offer_details.user_offer.valid_until);
}

export function clearLoginMeta(){
  _removeFromStorage('user_xid');
  _removeFromStorage('payment_tier_name');
  _removeFromStorage('offer_valid_until');
}

export function getUserId(){
  return _getFromStorage("user_xid");
}

export function getPaymentTier(){
  return _getFromStorage("payment_tier_name");
}

export function setPaymentTier(tier){
  return _setToStorage("payment_tier_name", tier);
}

export function getOfferValidDate(){
  return _getFromStorage("offer_valid_until");
}

export function setOfferValidDate(validity){
  return _setToStorage("offer_valid_until", validity);
}
