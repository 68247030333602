import React from 'react';

import Nav from 'component/navigation';
import {LandingFooter} from 'component/footer';


export default function LandingLayout(props) {
  return (
    <div id={props.id} className="page-wrapper">

      <Nav />

      <div className="landing-content my-5">
      {/* <div className="slant slant-bottom-left-primary"></div> */}
        {props.children}
      </div>

      <LandingFooter />
    </div>
  );
}
