import React from 'react';
import {Link} from 'react-router-dom';


export function FooterSectionTitle(props){
  return(<h5 className="small text-uppercase mt-5 mb-3 text-muted">{props.title}</h5>)
}


export function FooterLink(props){
  return (
    <div className={"uppercase m-2 text-spacing-2"}>
      <Link className="black-bb-1-1" to={props.to}><i className="text-black-3"></i>{props.text}</Link>
    </div>  
  )
}

export function FooterExtLink(props){
  return (
    <div className={"uppercase m-2 text-spacing-2"}>
      <a href={props.href} className="black-bb-1-1"><i className="text-black-3"></i>{props.text}</a>
    </div>  
  )
}
