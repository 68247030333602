export const PROFILE = {
  "contact": {
    "twitter": "https://twitter.com/bytebeacon",
    "linkedin": "https://www.linkedin.com/company/bytebeacon",
    "github": "https://github.com/bytebeacon",
    "facebook": "https://www.facebook.com/ByteBeacon-109146270805201",
// // "https://www.quora.com/profile/"+contact.quora
// // "https://www.instagram.com/"+contact.instagram
// // "https://hub.docker.com/r/"+contact.docker
  }
};
