import React from "react";

// import './hero.scss';


export default class HeroComponent extends React.Component {

  render (){

    return (
      <section id="hero" className="my-5">
        <div className=" justify-content-between">

          <div className="mb-4">
            <h1 className="text-center">
              <span className="text-black-2">Your </span> 
              <span className="text-primary">digital </span>
              <span className="text-black-2">growth </span>
              <span className="text-secondary">beacon</span>
            </h1>
          </div>

          <div className="my-3 text-center">
            <h5 className="my-2 family-p text-spacing-2">
              Building self-serve <span className="text-primary">growth tools</span> for online <span className="text-secondary">SaaS business</span>
            </h5>
          </div>

          <div className="my-5 text-center">
            <div className="row text-spacing-2 family-code small uppercase">
              <span className="col d-none d-lg-block"></span>
              <span className="col text-muted">Easy integrations</span>
              <span className="col text-muted">Increased visibility</span>
              <span className="col text-muted">Actionable analytics</span>
              <span className="col d-none d-lg-block"></span>
            </div>
          </div>

          <div className="flex-center row">
            <div className=" col-6 img img-fluid text-center">
              <img className="hero" src="/static/images/hero.svg" alt="Building self-serve growth tools for online SaaS business" />
            </div> 
          </div>

        </div>
      </section>
    )
  }
}
